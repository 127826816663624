import { Box, Typography } from '@mui/material';
import React from 'react';
import './LoyaltyBonus.css'; // Ensure this file contains only necessary styles

export const LoyaltyBonus = () => {
    return (
        <Box
            sx={{
                width: '100%',
                marginTop: '5em',
                textAlign: 'center',
                position: 'relative',
                padding: '0 1em', // Padding to handle smaller screens
                overflow: 'hidden' ,// Ensures waves don't overflow their container,
                p: 0
            }}
            className='fade-in-target'
        >
            {/* Top wave */}
            <Box
                sx={{
                    width: '100%',
                    height: '10em', // Adjust the height if necessary
                    zIndex: 1, // Ensure it is above the content
                    overflow: 'hidden'
                }}
                
            >
                <img
                    src="/vectorwave.png" // Ensure you have a wave image for the top
                    alt="wave-top"
                    style={{
                        width: '100%',
                        height: '10em',

                    }}
                />
            </Box>
            <Typography
                variant="h4"
                gutterBottom
                sx={{
                    marginBottom: '1em',
                    color: 'text.primary',
                    fontSize: '0.85em',
                    color: 'red'
                }}
                
            >
                LIMITED TIME OFFER!
            </Typography>
            <Typography
                variant="h4"
                gutterBottom
                sx={{
                    marginBottom: '1em',
                    color: 'text.primary',
                    fontWeight: 'bold'
                }}
                
            >
                £100 Loyalty Bonus
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: '2em',
                    color: 'text.secondary',
                    maxWidth: '800px',
                    margin: '0 auto'
                }}
                
            >
                We value our loyal customers! Enjoy a £100 loyalty bonus when you fill out the form below and get started with our services. It's our way of saying thank you for choosing us and trusting us with your vending needs.
            </Typography>
            
            {/* Bottom wave */}
            <Box
                sx={{
                    width: '100%',
                    height: '10em', // Adjusted height for the image
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
         
                    zIndex: 0 // Ensure it is below the content
                }}
                
            >
                <img
                    src="/vectorwave.png" // Your existing bottom wave image
                    alt="wave-bottom"
                    style={{
                        width: '100%',
                        height: '10em',

                    }}
                />
            </Box>
        </Box>
    );
};
