import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ListItem, List, Card, Divider } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
//ICONS
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { LoyaltyBonus } from './LoyaltyBonus';
import LogoLoader from './LogoLoader';
import LogoCollection from './LogoCollection';
import { useModal } from './ModalContext';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: '1px solid',
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${'/static/images/templates/templates-images/hero-light.png'})`,
  outlineColor: 'hsla(220, 25%, 80%, 0.5)',
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(270, 100%, 55%, 0.1)',
    backgroundImage: `url(${'/static/images/templates/templates-images/hero-dark.png'})`,
    outlineColor: 'hsla(210, 100%, 80%, 0.1)',
  }),
}));
const scrollToSection = (sectionId) => {
  const sectionElement = document.getElementById(sectionId);
  const offset = 128;
  if (sectionElement) {
    const targetScroll = sectionElement.offsetTop - offset;
    sectionElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({
      top: targetScroll,
      behavior: 'smooth',
    });
  }
};
export default function Hero() {
  const { openModal } = useModal();


  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
        
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
            <Box
className="fade-in-target"
component="img"
src="/images/logo.png"
alt="Placeholder"
sx={{
  width: '10em',
  //height: 'auto',
  borderRadius: 1,
  transform: 'scaleX(-1) !important'
}}
/>
          <LogoLoader sx={{display: {xs: 'none', md: 'block'}}}/>
          <Typography
            variant="h1"
            className="fade-in-target"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',

            }}
          >
            Premier&nbsp;
            <Typography
            className="fade-in-target"
              component="span"
              variant="h1"
              sx={(theme) => ({
                textShadow: '2px 2px 8px hsl(270, 100%, 55%)',
                fontSize: 'inherit',
                color: 'primary.main',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
              Vending&nbsp;
            </Typography>
            Solutions
          </Typography>
          <Typography

            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
          >
            Empowering <strong>Oxfordshire</strong> Workplaces: Boost Employee Satisfaction & Effortlessly Generate Extra Revenue with Vending Machines in Your Business.
          </Typography>


          <Stack
                      className="fade-in-target"
            direction={{ xs: 'row', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: 'auto', alignItems: 'flex-end' }}
          >
            <Box

              component="img"
              src="/images/necta_snakky.png"
              alt="Placeholder"
              sx={{
                width: '10em',
                //height: 'auto',
                borderRadius: 1,
                transform: 'scaleX(-1) !important'
              }}
            />
            <Box >
              <Button variant="contained" color="primary" onClick={openModal}>
                ENQUIRE
              </Button>
            </Box>

            <Box

              component="img"
              src="/images/necta_snakky.png"
              alt="Placeholder"
              sx={{
                width: '10em',
                //height: 'auto',
                borderRadius: 1,
              }}
            />

          </Stack>
          <Box className="fade-in-target">
            <Typography><a href='/' onClick={(e) => { e.preventDefault(); scrollToSection('whyus') }}>Why choose us?</a></Typography>
          </Box>
          <Box className="fade-in-target" sx={{display: 'flex', flexDirection: 'row'}}>
          <EmailIcon sx={{mr: 1}}/>
            <Typography><a href='mailto:contact@josiasvending.co.uk' style={{color: '#aac8ff'}}>contact@josiasvending.co.uk</a></Typography>
          </Box>
          <Box className="fade-in-target"  sx={{display: 'flex', flexDirection: 'row'}}>
            <LocalPhoneIcon sx={{mr: 1}}/>
            <Typography><a href='tel:07759308919' style={{color: '#aac8ff', fontSize: '1.5em'}}>+44 77 5930 8919</a></Typography>
          </Box>
        </Stack>
        <StyledBox className='fade-in-target' id="image" sx={{ padding: '14px', paddingTop: '2em', height: 'max-content !important', paddingBottom: '2em' }} >
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '2em', mb: '1.5em' }} id='about'>

            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', }} className="fade-in-target">
              <CurrencyPoundIcon sx={{ fontSize: '3em', color: 'hsl(208.21deg 100% 50%)', mb: 2.5 }} />
              <Typography variant="h6" >
                &nbsp;&nbsp;<b>Generate Revenue: </b>Vending machines create a passive income stream for your business.
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} className="fade-in-target">
              <AddReactionIcon sx={{ fontSize: '3em', color: 'hsl(208.21deg 100% 50%)', mb: 2.5 }} />
              <Typography variant="h6">
                &nbsp;&nbsp;<b>Boost Productivity: </b>91% of employees say vending machines improve satisfaction and productivity.
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} className="fade-in-target">
              <MoreTimeIcon sx={{ fontSize: '3em', color: 'hsl(208.21deg 100% 50%)', mb: 2.5 }} />
              <Typography variant="h6" >
                &nbsp;&nbsp;<b>Save Time: </b>69% of employees appreciate the convenience of on-site vending.
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 3 }} className='fade-in-target'/>

          <Box sx={{ width: '100%', padding: '0px' }} >
  {/* Wrapper for blurring effect */}
  <Box 
    sx={{ 
      position: 'relative', 
      width: '100%', 
      height: 'auto'
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: '-2.8em',
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: 'url(/images/vending.png)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        filter: 'blur(8px)', // Adjust the blur intensity as needed
        zIndex: -1
      }}
    />

    <Box sx={{ 
      position: 'relative',
      zIndex: 1,
      marginTop: '3.3em'
    }}>
      <Card sx={{width: '90%', margin: 'auto', display: 'flex', padding: 3, boxShadow: 3, marginBottom: '1em', flexDirection: { xs: 'column', md: 'row' } }}>
        <Box sx={{ padding: '1em' }}  className='fade-in-target'>
          <Typography variant="h4" gutterBottom>
            About Us
          </Typography>
          <Typography paragraph>
            At <strong>Josias Vending</strong>, we're dedicated to bringing convenience and quality to workplaces across Oxfordshire through our premium vending solutions. Providing vending machines completely free-of-charge to local businesses. Whether you're looking to enhance employee satisfaction, provide convenient snack options, or simply add an extra revenue stream to your business, we've got you covered.
          </Typography>
        </Box>
        <Box
          className='fade-in-target'
          component="img"
          src="/images/vendingz1.jpg"
          alt="Placeholder"
          sx={{
            width: '12em',
            borderRadius: 1,
            margin: 'auto'
          }}
        />
      </Card>

      <Box className='fade-in-target' sx={{ width: '100%', borderRadius: '12px', paddingBottom: '1em', display: 'flex', flexDirection: { xs: 'column', md: 'row' } }} >
        
        <Card sx={{ padding: 3, boxShadow: 3, width: { xs: '98%', md: '40%' }, margin: 'auto', marginTop: '1em' }} >
          <Typography variant="h5" gutterBottom className="fade-in-target">
            Our Mission
          </Typography>
          <Typography paragraph className="fade-in-target">
            Our mission is to make life easier for businesses by offering hassle-free vending services that cater to your unique needs. We believe that a well-stocked vending machine can transform a workplace, providing employees with quick access to snacks, beverages, and other essentials without having to leave the premises. We're here to help your business thrive by keeping your team energized and your guests satisfied.
          </Typography>
        </Card>
        <Card sx={{ padding: 3, boxShadow: 3, width: { xs: '98%', md: '40%' }, margin: 'auto', marginTop: '1em' }}>
          <Typography variant="h5" gutterBottom className="fade-in-target">
            How It Works
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body1" className="fade-in-target">
                <strong>Free Installation:</strong> We take care of everything from delivery to installation, ensuring a seamless setup process.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1" className="fade-in-target">
                <strong>Full-Service Maintenance:</strong> Once your vending machine is installed, we handle all the restocking and maintenance, so you never have to worry about empty shelves or out-of-order machines.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1" className="fade-in-target">
                <strong>Commission-Based Revenue:</strong> As a thank you for partnering with us, we pay a commission to the business owner for every sale made from the vending machine. It’s an easy way to add an additional revenue stream without any upfront costs or ongoing responsibilities.
              </Typography>
            </ListItem>
          </List>
        </Card>

      </Box>
    </Box>
  </Box>
</Box>

        </StyledBox>
        
      </Container>
      <LogoCollection />
      <LoyaltyBonus className='fade-in-target'/>

      <Container maxWidth="md" >
        <Box
          id='whyus'
          sx={{
            mt: 6,
            mb: 2,
            p: 4,
            boxShadow: 3,
            borderRadius: 2,
          }}
          className="fade-in-target"
        >
          <Typography variant="h4" align="center" gutterBottom className="fade-in-target">
            Why Choose Us?
          </Typography>

          <Typography variant="h6" gutterBottom className="fade-in-target">
            Local Expertise in Oxfordshire
          </Typography>
          <Typography variant="body1" paragraph className="fade-in-target">
            As a dedicated local business, we understand the unique needs of Oxfordshire's vibrant business community. Our close proximity allows us to provide prompt, personalized service and ensures that your vending machines are always stocked and functioning optimally.
          </Typography>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h6" gutterBottom className="fade-in-target">
            Hassle-Free Installation
          </Typography>
          <Typography variant="body1" paragraph className="fade-in-target">
            We make the process simple and stress-free. From the initial consultation to the seamless installation of your vending machines, our team handles everything. You can focus on running your business while we take care of the details.
          </Typography>

          <Divider sx={{ my: 3 }}  className='fade-in-target' />

          <Typography variant="h6" gutterBottom  className='fade-in-target'>
            Comprehensive Maintenance and Support
          </Typography>
          <Typography variant="body1" paragraph  className='fade-in-target'>
            Once your vending machine is in place, you can rely on us for ongoing maintenance and restocking. We monitor inventory levels, perform routine checks, and address any issues swiftly, so your employees and customers always have access to their favorite snacks and beverages.
          </Typography>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h6" gutterBottom  className='fade-in-target'>
            Revenue Generation with No Upfront Costs
          </Typography>
          <Typography variant="body1" paragraph  className='fade-in-target'>
            Our commission-based model means that you can enjoy an additional stream of revenue without any upfront investment. We install, maintain, and restock the machines, and you earn a commission on every sale. It’s a risk-free way to enhance your business's amenities and profitability.
          </Typography>

          <Divider sx={{ my: 3 }}  className='fade-in-target'/>

          <Typography variant="h6" gutterBottom  className='fade-in-target'>
            Commitment to Quality and Variety
          </Typography>
          <Typography variant="body1" paragraph className='fade-in-target'>
            We offer a wide selection of products to cater to the diverse preferences of your employees and customers. From healthy snacks to popular beverages, our vending machines are stocked with high-quality options that keep everyone satisfied.
          </Typography>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h6" gutterBottom className='fade-in-target'>
            Environmentally Conscious
          </Typography>
          <Typography variant="body1" paragraph className='fade-in-target'>
            We care about our community and the environment. Our vending machines are energy-efficient, and we prioritize stocking eco-friendly and locally sourced products whenever possible, helping your business contribute to a greener Oxfordshire.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
