import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { LocalCafe, Fastfood, AttachMoney, Timer, Build, SupportAgent } from '@mui/icons-material';

const items = [
  {
    icon: <LocalCafe />, // Replace with a relevant icon
    title: 'Convenient Snack Options',
    description:
      'Our vending machines provide a variety of snacks and beverages right at your fingertips, enhancing workplace convenience.',
  },
  {
    icon: <Fastfood />, // Replace with a relevant icon
    title: 'Diverse Product Range',
    description:
      'From healthy snacks to indulgent treats, our vending machines offer a selection that caters to all tastes and preferences.',
  },
  {
    icon: <AttachMoney />, // Replace with a relevant icon
    title: 'Revenue Generation',
    description:
      'Generate additional income effortlessly with our commission-based system, turning your space into a profit center.',
  },
  {
    icon: <Timer />, // Replace with a relevant icon
    title: 'Efficient Operation',
    description:
      'Experience seamless operation with timely restocking and maintenance, ensuring your vending machines are always in top shape.',
  },
  {
    icon: <Build />, // Replace with a relevant icon
    title: 'Easy Maintenance',
    description:
      'Our vending machines are designed for easy maintenance, with built-in features that simplify upkeep and reduce downtime.',
  },
  {
    icon: <SupportAgent />, // Replace with a relevant icon
    title: 'Exceptional Support',
    description:
      'Benefit from our dedicated customer service team, offering reliable support and prompt resolution to any issues that may arise.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'hsl(220, 30%, 2%)',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
           className='fade-in-target'
        >
          <Typography component="h2" variant="h4">
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Explore why our product stands out: adaptability, durability,
            user-friendly design, and innovation. Enjoy reliable customer support and
            precision in every detail.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}  className='fade-in-target'>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'hsla(220, 25%, 25%, .3)',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                  boxShadow: 'none',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
