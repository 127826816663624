// MetaTags.js
import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ title, description, param }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="canonical" href={"https://www.josiasvending.co.uk/" + param} />
  </Helmet>
);

export default MetaTags;
