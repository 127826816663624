import React, { useState } from 'react';
import {
  Container,
  TextField,
  MenuItem,
  Typography,
  Grid,
  Box,
  IconButton,
  InputAdornment,
  Button,
  FormControl,
  InputLabel,
  Select,
  useTheme,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import InfoIcon from '@mui/icons-material/Info';
import SendIcon from '@mui/icons-material/Send';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';

export default function EnquiryForm({success}) {
  const theme = useTheme(); // Access the theme
  const [contactPreference, setContactPreference] = useState('email');
  const [contactValue, setContactValue] = useState('');

  const handleContactChange = (event) => {
    setContactPreference(event.target.value);
    setContactValue(''); // Reset the value when contact preference changes
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevents the default form submission

    // Collect form data
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());

    try {
      // Send form data to server
      const response = await fetch('/enquire', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        success();
        // Optionally handle success (e.g., clear the form, show a success message)
      } else {
        console.error('Form submission error');
        // Optionally handle error (e.g., show an error message)
      }
    } catch (error) {
      console.error('Fetch error:', error);
      // Optionally handle network error
    }
  };

  return (
    <Container maxWidth="sm" sx={{position: 'relative', zIndex: 9999}}>
      <Box
        sx={{
          
          mt: 4,
          p: 1,
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: theme.palette.background.paper, // Use theme's background color
        }}
      >
        <Typography variant="body1" align="center" gutterBottom>
          Please fill out the form below and we’ll get back to you shortly.
        </Typography>

        <form noValidate autoComplete="off" id="enquire-form" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* Enquiry Type */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Enquiry Type</InputLabel>
                <Select
                  variant="standard"
                  label="Enquiry Type"
                  startAdornment={
                    <InputAdornment position="start">
                      <InfoIcon />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="general">General Enquiry</MenuItem>
                  <MenuItem value="support">Support</MenuItem>
                  <MenuItem value="feedback">Feedback</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Name */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Postcode */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Postcode"
                name="postcode"
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Contact Preference */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Contact Preference</InputLabel>
                <Select
                  variant='standard'
                  value={contactPreference}
                  onChange={handleContactChange}
                  label="Contact Preference"
                  name="contactPreference"
                >
                  <MenuItem value="email">Email</MenuItem>
                  <MenuItem value="phone">Phone Number</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Contact Value */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={
                  contactPreference === 'email'
                    ? 'Email Address'
                    : 'Phone Number'
                }
                name="contactValue"
                variant="standard"
                value={contactValue}
                onChange={(e) => setContactValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {contactPreference === 'email' ? (
                        <EmailIcon />
                      ) : (
                        <PhoneIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Describe Your Enquiry */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Describe Your Enquiry"
                name="description"
                multiline
                minRows={6}  // Start with 6 rows
                maxRows={10} // Allow it to expand up to 10 rows
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InfoIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type='submit'
                variant="contained"
                color="primary"
                fullWidth
                endIcon={<SendIcon />}
                sx={{
                  backgroundColor: theme.palette.primary.main, // Ensure button uses primary color
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark, // Darken on hover
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
}
