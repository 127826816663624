import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import BasicModal from './components/BasicModal';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import SupportPage from './components/SupportPage'
import { LoyaltyBonus } from './components/LoyaltyBonus';
import { ModalProvider } from './components/ModalContext';
import MetaTags from './components/MetaTags';



export const brand = {
  50: 'hsl(270, 100%, 97%)',  // Very light purple, almost white
  100: 'hsl(270, 100%, 90%)',  // Very light, pale purple
  200: 'hsl(270, 100%, 80%)',  // Light purple
  300: 'hsl(270, 100%, 65%)',  // Moderately light purple
  400: 'hsl(270, 98%, 48%)',   // Medium purple
  500: 'hsl(270, 98%, 42%)',   // Slightly darker medium purple
  600: 'hsl(270, 98%, 55%)',   // Brighter purple
  700: 'hsl(270, 100%, 35%)',  // Dark purple
  800: 'hsl(270, 100%, 16%)',  // Very dark purple, almost black
  900: 'hsl(270, 100%, 21%)',  // Darker purple, slightly lighter than 800
};



// Define your sections
const sections = {
  "highlights": 'highlights',
  "about": 'about',
  "faq": 'faq',
  "whyus": 'chooseus',
  "yourexperience": 'features'
};

// ScrollToSection component
const ScrollToSection = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const elementId = hash.substring(1); // Remove the '#' from the hash
      let sdsd = Object.keys(sections).findIndex(e => e === elementId);
      const element = document.getElementById(Object.values(sections)[sdsd]);
      if (element) {
        const targetScroll = element.offsetTop - 128;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({
          top: targetScroll,
          behavior: 'smooth',
        });
      }
    }
  }, [hash]);

  return null;
};

export default function LandingPage() {
  const [mode, setMode] = React.useState('dark');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({
    palette: {
      mode, primary: {
        light: brand[200],
        main: brand[500],
        dark: brand[800],
        contrastText: brand[50],
        ...(mode === 'dark' && {
          contrastText: brand[50],
          light: brand[300],
          main: brand[400],
          dark: brand[800],
        }),
      }
    }
  });

  const MainPage = () => {
    const location = useLocation();
    function isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

      // Adjust visibility threshold to 10%
      const threshold = 0.02;

      return (
        (rect.top + rect.height * threshold) <= viewportHeight &&
        (rect.bottom - rect.height * threshold) >= 0 &&
        (rect.left + rect.width * threshold) <= viewportWidth &&
        (rect.right - rect.width * threshold) >= 0
      );
    }
    function checkElements() {
      document.querySelectorAll('.fade-in-target').forEach(el => {
        // Check if element is in viewport and has not been animated yet
        if (isElementInViewport(el) && !el.classList.contains('animated')) {

          el.classList.add('fade-in-forward', 'animated');
          el.classList.remove('fade-in-target')
        }
      });
    }

    useEffect(() => {
      window.addEventListener('scroll', checkElements);
      window.addEventListener('resize', checkElements);
      window.addEventListener('load', checkElements);
      window.addEventListener('popstate', checkElements);
      window.addEventListener('hashchange', checkElements);

      // Initial check in case elements are already in view
      document.addEventListener('DOMContentLoaded', checkElements);
      checkElements();

      const titles = {
        '/': 'Home | Josias Vending - Premier Vending Solutions in Oxfordshire',
        '/about': 'About Us | Josias Vending',
        '/whyus': 'Why Us | Josias Vending',
        '/your-experience': 'Your Experience | Josias Vending',
        '/highlights': 'Highlights | Josias Vending',
        '/faq': 'FAQ | Josias Vending',
        '/support': 'Support | Josias Vending'
      };

      // Get the title based on the current path
      const title = titles[location.pathname] || 'Josias Vending'; // Default title

      // Set the document title
      document.title = title;


      return () => {
        window.removeEventListener('scroll', checkElements);
        window.removeEventListener('resize', checkElements);
        window.removeEventListener('load', checkElements);
        window.removeEventListener('popstate', checkElements);
        window.removeEventListener('hashchange', checkElements);

      }
    }, []);

    useEffect(() => {
      checkElements();
    }, [location.pathname])


    const getMetaTags = () => {
      switch (location.pathname) {
        case '/':
          return {
            title: 'Home | Josias Vending - Premier Vending Solutions',
            description: 'Josias Vending offers seamless vending machine solutions in Oxfordshire with free installation, comprehensive maintenance, and revenue opportunities. Transform your workplace with our top-notch vending services.',
            param: ''
          };
        case '/about':
          return {
            title: 'About Us | Josias Vending',
            description: "Our mission is to make life easier for Oxfordshire businesses by offering hassle-free vending services that cater to your unique needs. We believe that a well-stocked vending machine can transform a workplace, providing employees with quick access to snacks, beverages, and other essentials without having to leave the premises.",
          param: 'about'
          };
        // Add more cases for other routes
        case '/whyus':
          return {
            title: 'Why Us | Josias Vending',
            description: "As a dedicated local business, we understand the unique needs of Oxfordshire's vibrant business community. Our close proximity vending machine service allows us to provide prompt, personalized service and ensures that your vending machines are always stocked and functioning optimally.",
            param: 'whyus'
          };

        case '/your-experience':
          return {
            title: "Your Experience | Josias Vending",
            description: "Generate passive income effortlessly with our commission-based vending machine service, turning your space into a profit center. Enjoy seamless operation with our full-service maintenance, including regular restocking and prompt repairs to keep your machines in top condition.",
          param: 'your-experience'
          };
        case '/highlights':
          return {
            title: 'Highlights | Josias Vending',
            description: "Explore why our product stands out: adaptability, durability, user-friendly design, and innovation. Enjoy reliable customer support and precision in every detail.",
          param: 'highlights'
          }
        case '/faq':
          return {
            title: 'Frequently Asked Questions | Josias Vending',
            description: "Find answers to common questions about our vending services in Oxfordshire. Explore our FAQ for information on getting started and how we help businesses thrive with hassle-free vending solutions.",
          param: 'faq'
          }
          case '/support':
            return {
              title: 'Support | Josias Vending',
              description: "Need assistance with vending services in Oxfordshire? Our Support page allows you to easily request refunds or submit stock requests. Get prompt help and ensure your vending solutions are always stocked and running smoothly.",
            param: 'support'
            }
        default:
          return {
            title: "Josias Vending - Premier Vending Solutions in Oxfordshire"
          }
      }
    };

    const metaTags = getMetaTags();

    return (
      <ThemeProvider theme={LPtheme}>
        <><MetaTags title={metaTags.title} description={metaTags.description} param={metaTags.param} />
        <CssBaseline />
        <AppAppBar mode={mode} />

        <SupportPage />
        <div style={{ opacity: location.pathname == '/support' ? '0' : '1' }}>
          <Hero />
          <Box sx={{ bgcolor: 'background.default' }}>

            <Features checkElements={checkElements} />
            <Divider />
            <Highlights />
            <Divider />
            <FAQ />
            <Divider />
            <Footer />
          </Box></div>

        <BasicModal />
</>
      </ThemeProvider>
    )
  }
  return (
    <ModalProvider>
      <Router>
        <ScrollToSection />
        <Routes>

          <Route path="/about" element={<MainPage />} />
          <Route path="/whyus" element={<MainPage />} />
          <Route path="/" element={<MainPage />} />
          <Route path="/highlights" element={<MainPage />} />
          <Route path="/support" element={<MainPage />} />
          <Route path="/your-experience" element={<MainPage />} />
          <Route path="/faq" element={<MainPage />} />
          {/* Add other routes if needed */}
        </Routes>
      </Router>
    </ModalProvider>
  );
}

