import React, { useState, useEffect } from 'react';
import './LogoLoader.css'; // Ensure the path is correct
import { Typography } from '@mui/material';

const LogoLoader = ({ appbar, sx }) => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const checkFontLoad = () => {
      const testElement = document.createElement('span');
      testElement.style.fontFamily = "Satisfy"; // Ensure this matches your font family
      testElement.style.position = 'absolute';
      testElement.style.visibility = 'hidden';
      testElement.innerText = 't'; // Single character for faster measurement
      document.body.appendChild(testElement);

      const initialFont = '';
      const isFontLoaded = () => {
        const currentFont = window.getComputedStyle(testElement).fontFamily;
        return currentFont !== initialFont; // Font change indicates loading
      };

      const interval = setInterval(() => {
        if (isFontLoaded()) {
          setFontLoaded(true);
          clearInterval(interval);
          document.body.removeChild(testElement); // Remove test element after font is detected
        }
      }, 300);

      // Cleanup in case of component unmount
      return () => {
        clearInterval(interval);
        document.body.removeChild(testElement);
      }}
      checkFontLoad();
    }, []);

  useEffect(() => {
    if (fontLoaded) {
      setTimeout(() => {
        setShowLoader(false);
      }, 300);
    }
  }, [fontLoaded]);

  return (
    <div className="logo-container">
      {showLoader && <div className="loading-rectangle"></div>}
      <div className={`logo-text ${fontLoaded ? 'loaded' : ''}`}>
        <Typography
        className={appbar? 'animate-logo': ''}
          component="span"
          variant="h1"
          sx={(theme) => ({
            ...sx,
            p: 1,
            userSelect: 'none',
            fontFamily: "'Satisfy', cursive",
            fontWeight: '400',
            fontStyle: 'normal',
            fontSize: '2em',
            color: theme.palette.primary.main,
            ...theme.applyStyles('dark', {
              color: theme.palette.primary.light,
            }),
          })}
        >
           Josias Vending
        </Typography>
      </div>
    </div>
  );
};

export default LogoLoader;
