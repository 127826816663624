import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import {
  Container,
  MenuItem,
  Grid,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import InfoIcon from '@mui/icons-material/Info';
import SendIcon from '@mui/icons-material/Send';
import EnquiryForm from './EnquiryForm';
import { useEffect } from 'react';
import { useModal } from './ModalContext';
import { useNavigate } from 'react-router-dom';

function BasicModal() {
  const { isOpen, closeModal } = useModal();
  const navigate = useNavigate();
  const [done, setDone] = useState(false)
  const success = () => {
    setDone(true)
  }

  return (
      <Modal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        
      >

        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            borderRadius: 2,

          }}
        >         {done ? <h3>We have recieved your enquiry, we will be in touch soon.</h3>:<><Box sx={{display: 'flex', justifyContent: 'flex-end'}}>          <Button onClick={closeModal} sx={{ mt: 2 }}>
        Close
      </Button></Box>
          <Typography id="modal-title" variant="h6" component="h2">
            Enquire: 
          </Typography>

          <EnquiryForm success={success}/>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent:  'space-between'}}>
          <Button onClick={closeModal} sx={{ mt: 2 }}>
            Close
          </Button>
          <Button onClick={() => navigate('/support')} sx={{ mt: 2 }}>
            Support
          </Button>
          </Box>
</>}</Box>
      </Modal>
  );
}

export default BasicModal;