import logo from './logo.svg';
import './App.css';
import LandingPage from './landing-page/LandingPage';
function App() {
  return (
    <div className="App">
      <LandingPage />
      <div style={{position: 'absolute', left: '-9999px', top: '-9999px',}}>
    <h1>Josias Vending - Premier Vending Machine Solutions in Oxfordshire</h1>
    <h2>Top-Quality Vending Machines in Oxfordshire</h2>
    <h3>Comprehensive Vending Services for Businesses in Oxfordshire</h3>
    <p>At Josias Vending, we provide a wide range of vending machine solutions tailored to meet the needs of businesses across Oxfordshire.</p>

    <h3>Snack and Drink Vending Machines in Oxfordshire</h3>
    <p>Our vending machines offer a diverse selection of snacks, drinks, and healthy options, ensuring satisfaction for all customers.</p>

    <h3>Customized Vending Machine Options in Oxfordshire</h3>
    <p>Josias Vending specializes in custom vending machine setups, providing flexible options to suit any business environment.</p>

    <h3>Why Choose Josias Vending for Your Vending Needs in Oxfordshire?</h3>
    <p>We offer reliable, high-quality vending machines with regular maintenance and excellent customer support, making us the preferred choice for vending solutions in Oxfordshire.</p>

    <h3>Vending Machine Maintenance and Support Services in Oxfordshire</h3>
    <p>Josias Vending provides ongoing maintenance, restocking, and technical support to ensure your vending machines operate efficiently at all times.</p>

    <h3>Vending Machine Rental and Leasing in Oxfordshire</h3>
    <p>Explore our flexible vending machine rental and leasing options, perfect for businesses looking to provide convenient snack and drink solutions without upfront costs.</p>

    <h3>Eco-Friendly Vending Machines in Oxfordshire</h3>
    <p>Our eco-friendly vending machines are designed to reduce energy consumption and minimize environmental impact, aligning with your business's sustainability goals.</p>

    <h3>Healthy Vending Options in Oxfordshire</h3>
    <p>Josias Vending offers a variety of healthy snacks and beverages, catering to the growing demand for nutritious options in the workplace.</p>

    <h3>Vending Machine Installation and Setup in Oxfordshire</h3>
    <p>Our expert team provides seamless installation and setup of vending machines, ensuring a hassle-free experience for businesses in Oxfordshire.</p>

    <h3>Josias Vending - Your Trusted Vending Partner in Oxfordshire</h3>
    <p>With years of experience and a commitment to excellence, Josias Vending is your go-to provider for vending machine solutions in Oxfordshire.</p>

    <h3>Contact Josias Vending for Vending Machine Services in Oxfordshire</h3>
    <p>Get in touch with Josias Vending today to discuss your vending machine needs and learn how we can provide the best solutions for your business in Oxfordshire.</p>

    <h4>Local Vending Machine Services in Oxfordshire</h4>
    <p>Josias Vending is proud to serve the Oxfordshire area, offering top-notch vending machine services to businesses, schools, and public facilities.</p>

    <h4>Office Vending Machines in Oxfordshire</h4>
    <p>Enhance your office environment with our modern, convenient vending machines, stocked with a variety of snacks and beverages.</p>

    <h4>Vending Solutions for Schools and Universities in Oxfordshire</h4>
    <p>Josias Vending provides tailored vending solutions for educational institutions, ensuring students and staff have access to quality snacks and drinks.</p>

    <h4>Convenient Payment Options for Vending Machines in Oxfordshire</h4>
    <p>Our vending machines support multiple payment options, including cashless transactions, to offer maximum convenience for your customers.</p>

    <h4>Josias Vending - Leading Vending Machine Provider in Oxfordshire</h4>
    <p>Experience the best in vending machine technology and service with Josias Vending, the leading provider in Oxfordshire.</p>
</div>

    </div>
  );
}

export default App;
