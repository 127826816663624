import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Chip as MuiChip } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import { LocalAtm, SyncAlt, ShoppingBasket, LocalShipping } from '@mui/icons-material';

const items = [
  {
    icon: <LocalAtm />, // Icon for revenue generation
    title: 'Revenue Stream',
    description:
      'Generate passive income effortlessly with our commission-based vending machine service, turning your space into a profit center.',
    imageLight: 'url("/static/images/vending/revenue-light.png")',
    imageDark: 'url("/static/images/vending/revenue-dark.png")',
  },
  {
    icon: <SyncAlt />, // Icon for easy maintenance
    title: 'Hassle-Free Maintenance',
    description:
      'Enjoy seamless operation with our full-service maintenance, including regular restocking and prompt repairs to keep your machines in top condition.',
    imageLight: 'url("/static/images/vending/maintenance-light.png")',
    imageDark: 'url("/static/images/vending/maintenance-dark.png")',
  },
  {
    icon: <ShoppingBasket />, // Icon for product variety
    title: 'Diverse Product Selection',
    description:
      'Our vending machines offer a wide range of snacks and beverages to meet various preferences and dietary needs, enhancing your workplace convenience.',
    imageLight: 'url("/static/images/vending/products-light.png")',
    imageDark: 'url("/static/images/vending/products-dark.png")',
  },
  {
    icon: <LocalShipping />, // Icon for local service
    title: 'Local Service',
    description:
      'Benefit from our dedicated Oxfordshire-based team, ensuring quick and reliable service tailored to your local business needs.',
    imageLight: 'url("/static/images/vending/local-service-light.png")',
    imageDark: 'url("/static/images/vending/local-service-dark.png")',
  },
];

const Chip = styled(MuiChip)(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
        color: 'hsl(0, 0%, 100%)',
        borderColor: theme.palette.primary.light,
        '& .MuiChip-label': {
          color: 'hsl(0, 0%, 100%)',
        },
        ...theme.applyStyles('dark', {
          borderColor: theme.palette.primary.dark,
        }),
      },
    },
  ],
}));

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="your-experience" sx={{ py: { xs: 8, sm: 16 }, alignItems: 'center'}}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <Grid sx={{ width: '100%', pr: 4 }} className='fade-in-target'>
          <Grid item xs={12} md={6}>
            <div>
              <Typography component="h2" variant="h4" sx={{ color: 'text.primary' }}>
                Your Experience:
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
              >
                {/* Optional introductory text here */}
              </Typography>
            </div>
            <Grid container item sx={{ gap: 1, display: { xs: 'auto', md: 'none' } }}>
              {items.map(({ title }, index) => (
                <Chip
                  key={index}
                  label={title}
                  onClick={() => handleItemClick(index)}
                  selected={selectedItemIndex === index}
                />
              ))}
            </Grid>

            <Stack
              direction="column"
              spacing={2}
              useFlexGap
              sx={{
                mb: 4,
                justifyContent: 'center',
                alignItems: 'flex-start',
                width: '100%',
                display: { xs: 'none', md: 'flex' },
              }}
              className='fade-in-target'
            >
              {items.map(({ icon, title, description }, index) => (
                <Card
                  key={index}
                  component={Button}
                  onClick={() => handleItemClick(index)}
                  sx={[
                    (theme) => ({
                      p: 3,
                      height: 'fit-content',
                      width: '100%',
                      background: 'none',
                      '&:hover': {
                        background:
                          'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
                        borderColor: 'primary.light',
                        boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
                        ...theme.applyStyles('dark', {
                          background:
                            'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                          borderColor: 'primary.dark',
                          boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5)',
                        }),
                      },
                    }),
                    selectedItemIndex === index && ((theme) => ({
                      backgroundColor: 'action.selected',
                      borderColor: 'primary.light',
                      ...theme.applyStyles('dark', {
                        borderColor: 'primary.dark',
                      }),
                    })),
                  ]}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      textAlign: 'left',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { md: 'center' },
                      gap: 2.5,
                    }}
                  >
                    <Box
                      sx={[
                        (theme) => ({
                          color: 'grey.400',
                          ...theme.applyStyles('dark', {
                            color: 'grey.600',
                          }),
                        }),
                        selectedItemIndex === index && {
                          color: 'primary.main',
                        },
                      ]}
                    >
                      {icon}
                    </Box>
                    <div>
                      <Typography
                        gutterBottom
                        sx={{ color: 'text.primary', fontWeight: 'medium' }}
                      >
                        {title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary', mb: 1.5 }}
                      >
                        
                      </Typography>
                    </div>
                  </Box>
                </Card>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <Box sx={{ position: 'relative', width: '100%' }} className='fade-in-target'>
          <Box
            component="img"
            src="/images/vendingz2.jpg"
            alt="Placeholder"
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: '12px',
              mt: 3
            }}
            
          />
          {selectedItemIndex !== null && (
            <Box
              sx={{
                mt: 3,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.65)', // Semi-transparent background
                color: 'white',
                borderRadius: '12px',
                p: 2,
              }}
            >
              <Stack spacing={1} sx={{ textAlign: 'center' }}>
                <Typography variant="h6" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                  {items[selectedItemIndex].title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  {items[selectedItemIndex].description}
                </Typography>
              </Stack>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
}
