import * as React from 'react';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ToggleColorMode from './ToggleColorMode';
import { Typography } from '@mui/material';
import Sitemark from './SitemarkIcon';
import LogoLoader from './LogoLoader';
import { useNavigate } from 'react-router-dom';
import { useModal } from './ModalContext';
import { SocialLinks } from './SocialLinks';

function AppAppBar({ mode, handleClick }) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { openModal } = useModal();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 100;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };
  const navigateAndScroll = (id) => {
    navigate('/' + id)
    scrollToSection(id);

  }
  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}
      className='fade-in-target'
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            borderRadius: '999px',
            backdropFilter: 'blur(24px)',
            maxHeight: 40,
            border: '1px solid',
            borderColor: 'divider',
            bgcolor: 'hsla(220, 60%, 99%, 0.6)',
            boxShadow:
              '0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5)',
            ...theme.applyStyles('dark', {
              bgcolor: 'hsla(220, 0%, 0%, 0.7)',
              boxShadow:
                '0 1px 2px hsla(210, 0%, 0%, 0.5), 0 2px 12px hsla(210, 100%, 25%, 0.3)',
            }),
          })}
        >
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <LogoLoader appbar />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }} >
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => navigateAndScroll('about')}

              >
                About
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => navigateAndScroll('whyus')}
              >
                Why us?
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => navigateAndScroll('your-experience')}
              >
                Your Experience
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => navigateAndScroll('highlights')}
              >
                Highlights
              </Button>

              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => navigateAndScroll('faq')}
                sx={{ minWidth: 0 }}
              >
                FAQ
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => navigateAndScroll('support')}
                sx={{ minWidth: 0 }}
              >
                Support
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 0.5,
              alignItems: 'center',
            }}
          > 
            <Button color="primary" variant="contained" size="small" onClick={openModal}>
              ENQUIRE NOW
            </Button>
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >

                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton><SocialLinks style={{display: 'flex', flexDirection:  'row'}}/>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem onClick={() => navigateAndScroll('about')}>
                  About
                </MenuItem>
                <MenuItem onClick={() => navigateAndScroll('whyus')}>
                  Why us?
                </MenuItem>
                <MenuItem onClick={() => navigateAndScroll('your-experience')}>
                  Your Experience
                </MenuItem>
                <MenuItem onClick={() => navigateAndScroll('highlights')}>
                  Highlights
                </MenuItem>
                <MenuItem onClick={() => navigateAndScroll('faq')} sx={{ minWidth: 0 }}>
                  FAQ
                </MenuItem>
                <MenuItem onClick={() => navigateAndScroll('support')} sx={{ minWidth: 0 }}>
                  Support
                </MenuItem>

                <MenuItem onClick={() => navigateAndScroll('faq')}>FAQ</MenuItem>
                <MenuItem>
                  <Button color="primary" variant="contained" fullWidth>
                    ENQUIRE NOW
                  </Button>
                </MenuItem>
                <MenuItem>
                <Box className="fade-in-target" sx={{display: 'flex', flexDirection: 'row'}}>
          <EmailIcon sx={{mr: 1}}/>
            <Typography><a href='mailto:contact@josiasvending.co.uk' style={{color: '#aac8ff'}}>contact@josiasvending.co.uk</a></Typography>
          </Box> </MenuItem><MenuItem>
          <Box className="fade-in-target"  sx={{display: 'flex', flexDirection: 'row'}}>
            <LocalPhoneIcon sx={{mr: 1}}/>
            <Typography><a href='tel:07759308919' style={{color: '#aac8ff'}}>+44 77 5930 8919</a></Typography>
          </Box> </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired
};

export default AppAppBar;
