import React, { useState } from 'react';
import getLPTheme from '../getLPTheme';
import { createTheme } from '@mui/material/styles';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Container,
  Box
} from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useLocation } from 'react-router-dom';

const SupportPage = () => {
  const [openRefund, setOpenRefund] = useState(false);
  const [openStock, setOpenStock] = useState(false);
  const LPtheme = createTheme(getLPTheme('dark'));
  const location = useLocation();

  const handleOpenRefund = () => setOpenRefund(true);
  const handleCloseRefund = () => setOpenRefund(false);

  const handleOpenStock = () => setOpenStock(true);
  const handleCloseStock = () => setOpenStock(false);

  const handleSubmit = async (event, url) => {
    event.preventDefault();
    const formId = event.target.id;

    // Collect form data
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    console.log(data);

    try {
      // Send form data to server
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        // Optionally handle success (e.g., clear the form, show a success message)
      } else {
        console.error('Form submission error');
        // Optionally handle error (e.g., show an error message)
      }
    } catch (error) {
      console.error('Fetch error:', error);
      // Optionally handle network error
    }

    if (formId === 'refund-form') {
      handleCloseRefund();
    } else if (formId === 'stock-form') {
      handleCloseStock();
    }
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <Container
        sx={{
          zIndex: 25,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: location.pathname === '/support' ? 'block' : 'none',
        }}
        id="support"
      >
        <Box textAlign="center" my={4}>
          <SupportAgentIcon sx={{ fontSize: '8em', color: 'hsl(270, 100%, 35%)' }} />
          <Typography variant="h4" gutterBottom>
            Support
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenRefund}
            style={{ margin: '10px' }}
          >
            Request Refund
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenStock}
            style={{ margin: '10px' }}
          >
            Request Stock
          </Button>
        </Box>

        <Container>
          {/* Request a Refund Section */}
          <Box sx={{ marginBottom: '3em' }}>
            <Typography variant="h4" gutterBottom>
              Request a Refund
            </Typography>
            <Typography variant="body1" paragraph>
              We understand that sometimes things don’t go as planned. If you need to request a refund, please provide the details of your purchase and the reason for your request. Our team will review your request and get back to you as soon as possible.
            </Typography>
          </Box>

          {/* Request Stock in a Specific Location Section */}
          <Box>
            <Typography variant="h4" gutterBottom>
              Request Stock in a Specific Location
            </Typography>
            <Typography variant="body1" paragraph>
              If you need to request stock for a particular location, please provide the details of the location and the type and quantity of stock required. We will do our best to fulfill your request and ensure that you have the necessary resources for your needs.
            </Typography>
          </Box>
        </Container>

        {/* Refund Form Dialog */}
        <Dialog open={openRefund} onClose={handleCloseRefund}>
          <DialogTitle>Request Refund</DialogTitle>
          <DialogContent>
            <form onSubmit={(e) =>handleSubmit(e, '/refund')} id="refund-form">
              <TextField
                autoFocus
                variant='outlined'
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                name="name"  // Add name attribute
                required
              />
              
              <TextField
                margin="dense"
                label="Phone Number"
                type="tel"
                fullWidth
                name="phoneNumber"  // Add name attribute
                required
              />
              <TextField
                margin="dense"
                label="Location"
                type="text"
                fullWidth
                name="location"  // Add name attribute
                required
              />
              <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                multiline
                rows={4}
                name="description"  // Add name attribute
                required
              />              <DialogActions>
                <Button onClick={handleCloseRefund} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>

        {/* Stock Request Form Dialog */}
        <Dialog open={openStock} onClose={handleCloseStock}>
          <DialogTitle>Request Stock</DialogTitle>
          <DialogContent>
            <form onSubmit={(e) => handleSubmit(e, '/stock-request')} id="stock-form">
              <TextField
                autoFocus
                margin="dense"
                label="E-mail"
                type="email"
                fullWidth
                required
                name='email'
              />
              <TextField
                margin="dense"
                label="VendID / Location"
                type="text"
                fullWidth
                required
                name='location'
              />
              <TextField
                margin="dense"
                label="Details of Stock Request"
                type="text"
                fullWidth
                required
                name='description'
              />
              <DialogActions>
                <Button onClick={handleCloseStock} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
};

export default SupportPage;
