import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const SocialLinks = ({style}) => {
  return (
    <div className="social-links fade-in-target" style={{...style}}>
      <a href="https://www.facebook.com/profile.php?id=61564483591164" target="_blank" rel="noopener noreferrer" className="social-icon">
        <FacebookIcon style={{color: 'gray', width: '2rem', height: '2rem', marginRight: '1rem'}}/>
      </a>
      <a href="https://www.facebook.com/profile.php?id=61564483591164" target="_blank" rel="noopener noreferrer" className="social-icon">
        <InstagramIcon  style={{color: 'gray', width: '2rem', height: '2rem', marginRight: '1rem'}}/>
      </a>
      <a href="https://www.facebook.com/profile.php?id=61564483591164" target="_blank" rel="noopener noreferrer" className="social-icon">
        <LinkedInIcon  style={{color: 'gray', width: '2rem', height: '2rem', marginRight: '1rem'}}/>
      </a>
      <a href="https://www.facebook.com/profile.php?id=61564483591164" target="_blank" rel="noopener noreferrer" className="social-icon">
        <YouTubeIcon  style={{color: 'gray', width: '2rem', height: '2rem', marginRight: '1rem'}}/>
      </a>

    </div>
  );
}